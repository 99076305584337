import { FullAudit, isDefined, StaffUser, StaffUserRole, WhiteLabelDomain } from "@vaultinum/vaultinum-api";
import {
    addQueryParamsToUrl,
    CodeIcon,
    formatDisplayName,
    FullAuditStatusTag,
    getFullAudits,
    getFullAuditsByExpert,
    ScopeCyberIcon,
    ScopeEsgIcon,
    ScopeGDPRIcon,
    ScopeIPIcon,
    ScopeSoftwareIcon,
    Spin,
    SurveyIcon,
    TableStorageKey,
    Tabs,
    Tooltip,
    useUrlSearch
} from "@vaultinum/vaultinum-sdk";
import classNames from "classnames";
import { intersection, kebabCase, uniqBy } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getDomains } from "../../../services/domainService";
import { getStaffUsers } from "../../../services/staffUserService";
import { Domain, StandardModelListView } from "../components";
import { DeleteSurveyReport } from "./DeleteSurveyReport";
import { FullAuditEventsList } from "./FullAuditEventsList";
import NonDisclosureAgreementView from "./non-disclosure-agreement/NonDisclosureAgreementView";
import { FullAuditTabPage, getAccountsLinkByDomain } from "../../../services";

function ScopeIcon({ title, isSelected, IconComponent }: { title: string; isSelected: boolean; IconComponent: React.ElementType }): JSX.Element {
    return (
        <Tooltip
            title={title}
            children={
                <div
                    className={classNames("h-8 w-8 rounded-md p-1.5 ", {
                        "bg-green-light bg-opacity-30": isSelected,
                        "bg-grey-extra-light": !isSelected
                    })}
                >
                    <IconComponent size={20} {...(isSelected ? { color: "green", shade: "dark" } : { color: "grey" })} />
                </div>
            }
        />
    );
}

function renderFullAuditScopes(scopes: FullAudit.Scope[] = []): JSX.Element {
    return (
        <div className="flex justify-center gap-2">
            <ScopeIcon IconComponent={ScopeSoftwareIcon} title="Development" isSelected={scopes.includes(FullAudit.Scope.DEV)} />
            <ScopeIcon IconComponent={ScopeIPIcon} title="Intellectual Property" isSelected={scopes.includes(FullAudit.Scope.IP)} />
            <ScopeIcon IconComponent={ScopeCyberIcon} title="Cyber Security" isSelected={scopes.includes(FullAudit.Scope.CYBER)} />
            <ScopeIcon IconComponent={ScopeEsgIcon} title="Environment, Social and Governance" isSelected={scopes.includes(FullAudit.Scope.ESG)} />
            <ScopeIcon IconComponent={ScopeGDPRIcon} title="GDPR" isSelected={scopes.includes(FullAudit.Scope.GDPR)} />
        </div>
    );
}

function renderStatus(status: FullAudit.Status, comment?: string): JSX.Element {
    return (
        <Tooltip title={comment}>
            <FullAuditStatusTag status={status} />
        </Tooltip>
    );
}

function renderContacts(clients: string[]): JSX.Element {
    return (
        <div className="flex cursor-default flex-col space-y-1">
            <Tooltip placement="left" title="Audited client">
                <div className="flex items-center gap-2">
                    <span>🧑‍💻</span>
                    {clients[0]}
                </div>
            </Tooltip>
            <Tooltip placement="left" title="Beneficiary client">
                <div className="flex items-center gap-2">
                    <span>🔖</span>
                    {clients[1] || <span className="italic text-grey-light">N/A</span>}
                </div>
            </Tooltip>
        </div>
    );
}

function renderReviewers(reviewerIds: string[], staffUsers: StaffUser[]) {
    return <FullAuditReviewers reviewerIds={reviewerIds} staffUsers={staffUsers} />;
}

function renderFullAuditReports(fullAudit: FullAudit): JSX.Element {
    return (
        <div className="flex justify-center gap-1">
            {[
                ...(fullAudit.onlineAssessment?.surveyKeys?.length
                    ? [{ icon: SurveyIcon, hasReport: fullAudit.reportIds?.surveyReportId, title: "Online Assessments" }]
                    : []),
                ...(fullAudit.codeAudit.isRequired ? [{ icon: CodeIcon, hasReport: fullAudit.reportIds?.codeAuditReportId, title: "Code Audit Review" }] : [])
            ].map(({ icon: Icon, hasReport, title }) => (
                <Tooltip title={title} key={title?.toString()}>
                    <span
                        className={classNames("flex cursor-pointer items-center justify-center rounded-md p-1.5", {
                            "bg-green-light bg-opacity-30 text-green-dark": hasReport,
                            "bg-grey-light text-grey-dark": !hasReport
                        })}
                    >
                        <Icon color={!hasReport ? "grey" : "green"} shade={hasReport || !hasReport ? "dark" : "primary"} />
                    </span>
                </Tooltip>
            ))}
        </div>
    );
}

function FullAuditReviewers({ reviewerIds = [], staffUsers = [] }: { reviewerIds: string[]; staffUsers?: StaffUser[] }) {
    const assignedReviewers = intersection(
        reviewerIds,
        staffUsers.map(({ id }) => id)
    )
        .map(reviewerId => staffUsers.find(({ id }) => id === reviewerId))
        .filter(isDefined);

    return assignedReviewers.length ? (
        <ul>
            {assignedReviewers.map(reviewer => (
                <li key={reviewer.id} children={formatDisplayName(reviewer)} />
            ))}
        </ul>
    ) : (
        <span className="italic text-grey-light">N/A</span>
    );
}

export function RequestsList({ staffUser }: { staffUser: StaffUser }) {
    const navigate = useNavigate();
    const searchUrlParams = useUrlSearch() as { tab?: string };
    const [domains, setDomains] = useState<WhiteLabelDomain[]>();
    const [staffUsers, setStaffUsers] = useState<StaffUser[]>();
    const [fullAudits, setFullAudits] = useState<FullAudit[]>();

    useEffect(() => getDomains(setDomains), []);
    useEffect(() => getStaffUsers(setStaffUsers), []);
    useEffect(() => {
        if (staffUser.roles.includes(StaffUserRole.CLIENT_SERVICE)) {
            return getFullAudits(setFullAudits);
        } else if (staffUser.roles.includes(StaffUserRole.KYS2_REVIEWER)) {
            return getFullAuditsByExpert(staffUser.id, undefined, setFullAudits);
        }
        return () => {};
    }, [staffUser]);

    const fullAuditsDomains = useMemo(() => {
        if (!fullAudits || !domains) {
            return [];
        }
        return uniqBy(fullAudits.map(fullAudit => domains.find(({ id }) => id === fullAudit.whiteLabelDomainId)).filter(isDefined), "id");
    }, [fullAudits, domains]);

    const fullAuditsStaffUsers = useMemo(() => {
        if (!fullAudits || !staffUsers) {
            return [];
        }
        return uniqBy(fullAudits.map(fullAudit => staffUsers.find(({ id }) => fullAudit.reviewerIds?.includes(id))).filter(isDefined), "id");
    }, [fullAudits, staffUsers]);

    if (!domains) {
        return <Spin />;
    }

    const activeTab = searchUrlParams.tab ?? kebabCase(FullAuditTabPage.Tab.requests);

    function onTabChange(newTab: string) {
        navigate(addQueryParamsToUrl(location.pathname, { ...searchUrlParams, tab: kebabCase(newTab) }));
    }

    const extraActions = (fullAudit: FullAudit) => {
        if (!staffUser.roles.includes(StaffUserRole.ADMIN) || !fullAudit.reportIds.surveyReportId) {
            return null;
        }
        return <DeleteSurveyReport surveyReportId={fullAudit.reportIds.surveyReportId} fullAuditId={fullAudit.id} />;
    };

    return (
        <Tabs activeKey={activeTab} onChange={onTabChange} className="h-full flex-1">
            <Tabs.TabPane className="icon space-y-2" tab={FullAuditTabPage.Tab.requests} key={kebabCase(FullAuditTabPage.Tab.requests)}>
                <StandardModelListView<FullAudit>
                    getModelItems={onUpdate => onUpdate(fullAudits || [])}
                    name="Due Diligence requests"
                    searchOptions={{ hideIgnored: true }}
                    extraActions={extraActions}
                    columns={[
                        {
                            header: "Status",
                            accessorKey: "status",
                            cell: cell => renderStatus(cell.getValue<FullAudit.Status>(), cell.row.original.comment),
                            size: 120,
                            defaultFilteredValues: Object.values(FullAudit.Status).filter(
                                status => ![FullAudit.Status.DONE, FullAudit.Status.CANCELLED].includes(status)
                            )
                        },
                        {
                            header: "Scope",
                            accessorKey: "scopes",
                            cell: cell => renderFullAuditScopes(cell.getValue<FullAudit.Scope[]>()),
                            size: 215
                        },
                        {
                            header: "Reports",
                            enableColumnFilter: false,
                            enableSorting: false,
                            accessorKey: "reportIds",
                            size: 85,
                            cell: cell => {
                                const fullAudit = cell.row.original;
                                return renderFullAuditReports(fullAudit);
                            }
                        },
                        {
                            header: "Project",
                            accessorFn: row => row.product.name
                        },
                        {
                            header: "Domain",
                            accessorKey: "whiteLabelDomainId",
                            cell: cell => Domain({ domainId: cell.getValue<string>(), domains, redirectTo: getAccountsLinkByDomain }),
                            filters: [
                                {
                                    label: "N/A",
                                    value: null,
                                    onFilter: row => !row.whiteLabelDomainId
                                },
                                ...fullAuditsDomains.map(domain => ({
                                    label: domain.fqdn,
                                    value: domain.id,
                                    onFilter: (row: FullAudit) => row.whiteLabelDomainId === domain.id
                                }))
                            ],
                            size: 140
                        },
                        {
                            header: "Contacts",
                            enableSorting: false,
                            accessorFn: row => [row.auditedAccount.email, row.beneficiaryAccount?.email],
                            cell: cell => renderContacts(cell.getValue<string[]>())
                        },
                        {
                            header: "Assigned Reviewers",
                            accessorKey: "reviewerIds",
                            enableSorting: false,
                            cell: cell => renderReviewers(cell.getValue<string[]>(), fullAuditsStaffUsers),
                            filters: [
                                {
                                    label: "N/A",
                                    value: undefined,
                                    onFilter: fullAudit => !fullAudit.reviewerIds?.length
                                },
                                ...(fullAuditsStaffUsers || []).map(staffUser => ({
                                    label: formatDisplayName(staffUser),
                                    value: staffUser.id,
                                    onFilter: (fullAudit: FullAudit) => !!fullAudit.reviewerIds?.includes(staffUser.id)
                                }))
                            ]
                        }
                    ]}
                    storageKey={TableStorageKey.FULL_AUDIT}
                />
            </Tabs.TabPane>
            <Tabs.TabPane
                className="icon space-y-2"
                tab={FullAuditTabPage.Tab.nonDisclosureAgreement}
                key={kebabCase(FullAuditTabPage.Tab.nonDisclosureAgreement)}
            >
                <NonDisclosureAgreementView />
            </Tabs.TabPane>
            <Tabs.TabPane className="icon space-y-2" tab={FullAuditTabPage.Tab.events} key={kebabCase(FullAuditTabPage.Tab.events)}>
                <FullAuditEventsList />
            </Tabs.TabPane>
        </Tabs>
    );
}
