import { FullAudit } from "@vaultinum/vaultinum-api";
import filesize from "filesize";
import { CommonLang } from "../CommonLang";

const locale = "en-US";
const formatSize = (size = 0): string => filesize(size, { base: 10, locale });

/* eslint max-len: 0 */
export const enCommon: CommonLang & {
    langs: {
        french: string;
        german: string;
        spanish: string;
        italian: string;
        debug: string;
    };
} = {
    code: "en",
    getLang: "English",
    apps: { name: "Applications" },
    langs: {
        french: "French",
        german: "German",
        spanish: "Spanish",
        italian: "Italian",
        debug: "Debug"
    },
    shared: {
        // shared between authentication, account and apps
        email: "Email",
        copy: "Copy",
        invalidEmail: "Invalid email",
        name: "Name",
        description: "Description",
        confirm: "Confirm",
        cancel: "Cancel",
        add: "Add",
        save: "Save",
        edit: "Edit",
        change: "Change",
        remove: "Remove",
        delete: "Delete",
        free: "Free",
        next: "Next",
        previous: "Previous",
        yes: "Yes",
        no: "No",
        skip: "Skip",
        submit: "Submit",
        done: "Done",
        back: "Back",
        unknown: "Unknown",
        loading: "Loading...",
        anyQuestion: "Any questions?",
        contactUs: "Contact us",
        requiredErrorMessage: "This field is required",
        minErrorMessage: minLength => `Minimum length is ${minLength} characters`,
        atLeastOneUpperLowercase: "At least one uppercase and one lowercase letter",
        atLeastOneSpecialCharacter: "At least one special character",
        atLeastOneDigit: "At least one digit",
        loginFailed: "Failed to sign in.",
        logoutFailed: "Failed to sign out.",
        saveSuccessMessage: "Saved successfully",
        saveErrorMessage: "Failed to save",
        inviteSuccessMessage: "Invitation sent successfully",
        inviteErrorMessage: "Failed to send invitation",
        sendEmailSuccessMessage: "Email sent successfully",
        sendEmailErrorMessage: "Failed to send email",
        signIn: "Sign In",
        invalidBirthdate: "Please specify a correct birthdate",
        dateFormat: "MM/DD/YYYY",
        successMessage: "Success",
        failMessage: "Something went wrong",
        password: "Password",
        confirmPassword: "Confirm password",
        everything: "All",
        updated: "Updated",
        update: "Update",
        apply: "Apply",
        logOutProgress: "Logging out...",
        emailMustBeDifferentFromYours: "The email address must be different from yours", // to review
        details: "Details",
        uploadDescription: fullAudit => (fullAudit ? "Manually upload your file" : "Deposit one or multiple files, compressed files or folders"),
        uploadMethod: "Upload method",
        uploadTitle: "Upload",
        seeAllItems: appInfoLang => `See ${appInfoLang?.allItemsName || "all"}`,
        overview: "Overview",
        selectedRepositories: {
            [1]: "1 repository selected",
            plural: count => `${count} repositories selected`
        },
        selectManually: "Select manually",
        upload: "Upload",
        processing: "Processing",
        ready: "Ready",
        noResult: "No result",
        included: "Included",
        search: "Search",
        results: {
            [0]: "0 result",
            [1]: "1 result",
            plural: count => `${count} results`
        },
        resultsWithTotal: {
            [0]: "0 result",
            pluralWithTotal: (count: number, totalCount: number) => `${count}/${totalCount} results`
        },
        characters: {
            [0]: "0 character",
            [1]: "1 character",
            plural: count => `${count} characters`
        },
        invalidPhoneNumber: "Invalid phone number",
        other: "Other",
        filterBy: (label: string) => `Filter by ${label.toLocaleLowerCase()}`,
        sortBy: (label: string) => `Sort by ${label.toLocaleLowerCase()}`,
        ascending: "Ascending",
        descending: "Descending",
        organisation: "Organisation",
        domain: "Domain",
        status: "Status",
        empty: "empty",
        months: {
            january: "January",
            february: "February",
            march: "March",
            april: "April",
            may: "May",
            june: "June",
            july: "July",
            august: "August",
            september: "September",
            october: "October",
            november: "November",
            december: "December"
        },
        changeFilter: "To widen your search, change or remove filter above",
        selectAccount: "Select account",
        failedFetchingAccount: "An error occurred while fetching the account.",
        others: "Others",
        noDataAvailable: "No data available"
    },
    menu: {
        failedToGetMenuItems: "An error occured while loading menu"
    },
    date: {
        on: "on",
        doneOn: () => "Done on",
        createdOn: () => "Created on",
        updatedOn: () => "Updated on",
        completedOn: () => "Completed on",
        requestedOn: () => "Requested on",
        addedOn: () => "Added on"
    },
    settingsMenu: {
        signOut: "Sign Out",
        signedInAs: "Signed in as:",
        companyAccount: "Organisation:",
        settings: "Settings",
        profileSettings: "Profile settings",
        accountSettings: "Organisation settings",
        switchTo: "Switch to another organisation:",
        manageAccounts: "Manage organisations",
        newOrganization: "New organisation"
    },
    price: {
        allPlans: "All Plans",
        priceOnDemand: "Price on demand", // to review
        getStarted: "Get Started", // to review
        title: {
            accessClause: "Access clause", // to review
            bipartite: "Bipartite", // to review
            tripartite: "Tripartite", // to review
            deposit: "Deposit", // to review
            depositUpdate: "Deposit Update", // to review
            depositUnlimitedPlan: "Unlimited Deposit Plan",
            elementCheckedDeposit: "Element checked Deposit", // to review
            contentCheckedDeposit: "Content Checked Deposit", // to review
            oneShot: "One Shot" // to review
        },
        features: {
            fiveSurveys: "Five surveys", // to review
            multiUserAccess: "Multi-User Access", // to review
            illustratedReport: "Illustrated report", // to review
            recommendations: "Recommendations", // to review
            customerSupport: "Customer Support", // to review
            oneSurvey: "One survey", // to review
            unlimitedNumberOfSurvey: "Unlimited number of surveys", // to review
            encryptedDeposit: "Encrypted deposit", // to review
            certificateOfDeposit: "Certificate of deposit", // to review
            freeUpdates: "Free updates", // to review
            contractTemplate: "Contract template", // to review
            legalTermsAtYourService: "Legal team at your service", // to review
            beneficiaryDashboardAccess: "Access to the dashboard for beneficiaries", // to review
            vaultinumAsSignator: "Vaultinum as signatory", // to review
            oneOnlineAssessment: "One Online-Assessment", // to review
            sourceCodeScan: "Source Code Scan", // to review
            expertAnalysis: "Expert Analysis", // to review
            multipleProducts: "Multiple Products", // to review
            multipleCompanies: "Multiple Companies" // to review
        }
    },
    payment: {
        checkout: {
            name: "Checkout"
        },
        proceed: "Proceed to payment",
        downloadReceipt: "Download receipt",
        downloadReceiptError: "An error occurred while downloading the receipt",
        payment: {
            price: "Price",
            promoCode: "Promo code",
            promoCodePlaceholder: "E.g: GPBM6PYXE",
            promoCodeError: "This promo code is not valid",
            taxes: taxValue => `Taxes (${taxValue}% VAT)`,
            name: "Payment",
            discount: "Discount",
            total: "Total",
            message: "Choose payment method:",
            creditCard: {
                name: "Credit or debit card",
                cardName: "Card name",
                saveAcceptance:
                    "By checking this box, you accept to save this payment method so that it is offered for your future orders. However, this will not replace your current default payment method."
            },
            sepaDebit: {
                name: "SEPA debit",
                accountHolder: {
                    name: "Account holder name",
                    namePlaceholder: "Full name"
                },
                iban: "IBAN",
                mandateAcceptance:
                    "By providing your payment information and confirming this payment, you authorise (A) Vaultinum and Stripe, our payment service provider, to send instructions to your bank to debit your account and (B) your bank to debit your account in accordance with those instructions. As part of your rights, you are entitled to a refund from your bank under the terms and conditions of your agreement with your bank. A refund must be claimed within 8 weeks starting from the date on which your account was debited. Your rights are explained in a statement that you can obtain from your bank. You agree to receive notifications for future debits up to 2 days before they occur."
            },
            activationCode: {
                name: "Activation code",
                message: "Input your activation code:",
                placeholder: "Activation code..."
            },
            method: "Payment method"
        },
        done: {
            name: "Done",
            message: "Your purchase was completed with success!"
        },
        failed: {
            name: "Failed",
            message: "There was an error during your payment, please retry.",
            backToPayment: "Back to payment",
            unableToConfirmCard: "Unable to confirm card",
            callbackError:
                "There was a problem during the payment finalization. You have been charged. If you try to pay again, you may be charged twice. Please contact us so we can give you a free coupon before you try again."
        },
        billing: {
            address1: "Address line 1",
            address: "Billing address",
            billingInformation: "Billing information",
            city: "City",
            companyName: "Organisation name",
            country: "Country",
            vatNumber: "VAT Number",
            vatNumberPlaceholder: "GB123456789",
            wrongVatFormat: "Wrong VAT format",
            taxIdRegistered: "VAT number already registered",
            notProvided: "Not provided",
            zipCode: "ZIP Code",
            billingDisclaimer: "Your details will be used for the purchase. Please make sure the details are not missing and that they are accurate.",
            formWarningMissingDetails: "Required billing details are missing",
            billingContact: "Billing contact",
            billingContactPlaceholder: "Enter billing contact email"
        },
        frequency: {
            day: "day",
            week: "week",
            month: "month",
            year: "year"
        },
        summary: {
            order: "Order summary",
            billingAddress: "Billing address",
            paymentMethod: "Payment method",
            disclaimer: "You will be receiving a confirmation email with order details"
        },
        method: {
            expired: "Expired",
            addPaymentMethod: "Add a payment method",
            expirationDate: "Expiration date",
            errorListingPaymentMethods: "An error occured while fetching the payment method list",
            errorAddingPaymentMethod: "An error occurred while adding the payment method"
        }
    },
    industryList: {
        businessConsultancyManagement: "Business, consultancy or management",
        accountancyBankingFinance: "Accountancy, banking or finance",
        energyUtilities: "Energy and utilities",
        engineeringManufacturing: "Engineering or manufacturing",
        environmentAgriculture: "Environment or agriculture",
        softwarePublisher: "Software Publisher",
        iTServices: "IT Services",
        law: "Law",
        lawEnforcementSecurity: "Law enforcement and security",
        leisureHospitalityTourism: "Leisure, hospitality or tourism",
        mediaDigitalPublicRelations: "Media, digital, PR",
        propertyConstruction: "Property or construction",
        publicServicesAdministration: "Public services or administration",
        scientificResearch: "Scientific Research",
        pharmaceuticalsHealthcare: "Pharmaceuticals, healthcare",
        education: "Education",
        transportLogistics: "Transport or logistics",
        other: "Other"
    },
    cookieConsent: {
        accept: "Accept",
        decline: "Decline",
        description: "We use cookies to analyze the traffic and enhance user experience. You can read our legal notice to learn more about how we handle data."
    },
    report: {
        completed: "Completed",
        industryAverage: "Industry average",
        score: "Score"
    },
    fileTree: {
        lines: "Lines",
        size: "Size"
    },
    codeInput: {
        codeValidationMessage: "Verifying code...",
        codeValidationMessageError: "Invalid code, please try again",
        codeValidationMessageSuccess: "Code validated."
    },
    register: {
        acceptTermsConditionsAndPrivacyPolicyErrorMessage: "Terms and Conditions and Privacy Policy must be accepted.",
        alreadyRegistered: "Already registered?",
        confirmPasswordErrorMessage: "Passwords do not match",
        createAccount: "Create account",
        iAcceptThe: "I accept the",
        join: company => `Join ${company}`,
        login: "Login",
        logout: "Log out",
        privacyPolicy: "the Privacy Policy",
        register: "Register",
        registerWelcome: "Fill in the form below to create a new account.",
        termsConditions: "General Conditions of Use of the Platform",
        failMessage: "Failed to register"
    },
    onboarding: {
        title: "Welcome to Vaultinum!",
        subtitle: "Trusted independent third party specialized in the protection and audit of digital assets."
    },
    client: {
        supplier: "Supplier",
        beneficiary: "Beneficiary"
    },
    fullAudit: {
        errors: {
            notFound: "Due Diligence not found."
        },
        status: {
            [FullAudit.Status.OPEN]: "Open",
            [FullAudit.Status.IN_PROGRESS]: "In progress",
            [FullAudit.Status.BLOCKED]: "Blocked",
            [FullAudit.Status.CANCELLED]: "Cancelled",
            [FullAudit.Status.DONE]: "Done"
        }
    },
    parties: {
        newParty: "New Party",
        sendInvitation: "Send invitation",
        sendInvitationByEmail: "Send invitation by email",
        inputSupplierEmail: "Please enter the email address of your contact:",
        invitation: companyName =>
            `Invite your contact to connect with “${companyName}” and begin collaborating on escrows and/or audits. Your new party will be able to view shared information on audits / escrows and your organisation name, address, and registration number. If your contact doesn't have a Vaultinum account yet, they will be invited to create one. Tick the box below if you want them to be notified by email.`,
        alreadyPartner: "This email adress is already a partner of your organisation",
        alreadyReceivedInvitation: "This email adress has already received an invitation",
        alreadySentInvitation: "An invitation has already been sent to this email adress",
        sendInvitationConfirmation: (email: string) => `Are you sure you want to send the invitation to the following email: ${email}?`
    },
    accountInformation: {
        title: "Organisation Information",
        form: {
            companyName: "Organisation name",
            companyRegistrationNumber: "Registration number",
            companyNationality: "Nationality",
            companyIndustry: "Industry",
            companySize: "Organisation size",
            address: {
                title: "Organisation address",
                line1: "Address",
                line1PlaceHolder: "Street name, number",
                line2: "Address line 2",
                line2PlaceHolder: "Building, floor",
                city: "City",
                postalCode: "Postal code",
                postalCodePlaceHolder: "00000/YY00Y",
                country: "Country"
            },
            billing: {
                invoiceCompanyName: "Invoice organisation name",
                billingInformationInvoiceCompanyName:
                    "By default, your organisation name is shown on your invoice. If you want to show a custom name instead, please enter it here:",
                billingInformationInvoicePostalAddress: "If you’d like to add a different postal address to every invoice, enter it here",
                billingInformationQuestions: "Any questions about your billing?"
            }
        },
        roleAdminIsRequired: "You need to have an Admin or Representative role to edit the organisation information",
        notes: {
            title: "Notes",
            deleteNote: "Delete note",
            showNotes: "Show notes",
            permanentlyDeleteNote: "This will permanently delete the note",
            visibleBy: "Visible by",
            updatedBy: "Last updated by",
            reviewers: "reviewers",
            membersOf: accountName => `members of ${accountName}`
        }
    },
    seo: {
        registerTitle: "Register on our online management platform", // to review
        registerDescription:
            "Create an organisation on our management platform and make an instant IP deposit, manage your software escrows or run a tech due diligence | ISO27001 certified", // to review
        loginTitle: "Log on our online management platform", // to review
        loginDescription:
            "Log on our collaborative management platform and make an instant IP deposit, manage your software escrows or run a tech due diligence | ISO27001 certified", // to review
        homeTitle: "Which action do you want to perform?", // to review
        homeDescription:
            "Vaultinum's collaborative management platform allows users to make an instant IP deposit, manage software escrows or run a tech due diligence | ISO27001" // to review
    },
    git: {
        gitConnection: "Git connection",
        description: fullAudit => {
            if (fullAudit) {
                return "Upload your code directly from a Git provider";
            } else {
                return "Synchronise your code repositories at regular intervals or according to Git events";
            }
        },
        notConfigured: "It seems you did not set up any Git connection yet",
        notConfiguredDetails:
            "To setup your online distributed version control system (GitHub for example), click on the button below and follow the instructions. Come back here when this is done.",
        selectSource: "Select source",
        selectRepository: "Select code repository",
        selectBranch: "Select branch",
        selectFrequency: "Select a frequency",
        defaultBranch: "default branch",
        frequency: {
            weekly: "Weekly",
            monthly: "Monthly",
            every3Month: "Every 3 months",
            every6month: "Every 6 months",
            oneShot: "One shot"
        },
        connectionActive: "Git connection active",
        errors: {
            connectionSuspended: "Git connection suspended",
            connectionUninstalled: "Git connection uninstalled",
            connectionNotAccessible: "Git connection not accessible",
            repositoryNotAccessible: "Git repository not accessible",
            repositoryNotFound: "Git repository not found",
            branchDeleted: "Branch deleted",
            archiveSizeExceeded: "Archive size exceeds limit",
            operationFailed: "Git operation failed"
        },
        addRepository: "Add code repository",
        addAllRepositories: "Add all code repositories",
        branch: "branch",
        selectedRepositories: "Selected code repositories",
        warningCheckoutConfirmation: "Once confirmed, you will no longer be able to edit the title, description and selected repositories",
        frequencyTitle: "Frequency",
        summaryError: "An error occurred while retrieving information",
        codeRepository: "Code repository",
        source: "Source",
        repositoriesError: "An error occurred while retrieving code repositories",
        setup: "Setup",
        codeRepositoryEmpty: "Please add some code repositories and select a branch"
    },
    table: {
        apply: "Apply",
        search: "Search",
        filter: "Filter",
        sort: "Sort",
        selectAll: "Select all",
        clearAll: "Clear all",
        sortAsc: "Sort by ascending order",
        sortDesc: "Sort by descending order"
    },
    email: {
        subject: {
            billingSupportRequest: "Support request - billing",
            supportRequest: "Support request"
        }
    },
    uploadFile: {
        description: (fileType: string) => `Select or drag & drop your ${fileType} file`,
        file: "File",
        size: "Size",
        noFileUploaded: "No file uploaded",
        preview: "Preview",
        formatSize,
        tooManyFiles: "Only one file is accepted",
        invalidFileType: (fileName: string, fileTypes: string) => `File ${fileName} should be one of type: ${fileTypes}`,
        acceptedFileTypes: (fileTypes: string) => `File type is not supported. Only : ${fileTypes} are accepted`,
        actions: "Actions",
        minResolution: (resolution: number) => `Minimum recommended size: ${resolution}px (width/height)`,
        errorFetchingFiles: "An error occurred while fetching files from our server. Please contact us to fix this issue before trying to upload files.",
        errorWhileUploading: "Error when uploading your file. Please contact our customer support.",
        files: "Files",
        filesAlreadyExistError: files => `${files} already exist(s)`,
        maxFileSizeError: (filename, maxFileSize) => `${filename} is bigger than ${maxFileSize}`,
        maxNumberOfFilesError: maxNumberOfFiles => `You cannot upload more than ${maxNumberOfFiles} files`,
        maxTotalFileSizeError: maxTotalFileSize => `You cannot upload more than ${maxTotalFileSize} files`,
        minNumberOfFilesError: "You need to upload at least one file",
        noFilesUploadedYet: "No files uploaded yet",
        notPossibleToDelete: filename => `It was not possible to delete ${filename}`,
        progress: "Progress",
        uploadInstructions: "Drag and drop your files here, or click to select files",
        uploadLimitations: (acceptedFileTypes, maxFileSize, maxTotalSize) =>
            `${
                !acceptedFileTypes?.length ? "All files extension are accepted" : `Only some file extensions are accepted: ${acceptedFileTypes}`
            }. Any one file cannot be bigger than ${maxFileSize}. The total size cannot exceed ${maxTotalSize}.`,
        waitUntilFinished: "You need to wait until the upload is finished"
    },
    chart: {
        monthAbbreviation: "m",
        yearAbbreviation: "y"
    }
};
