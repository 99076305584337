import classNames from "classnames";
import { ReactNode } from "react";
import { Color } from "../../referentials";

export type BaseCardContainerInterface = {
    children: ReactNode;
    leftChildren?: ReactNode;
    rightChildren?: ReactNode;
    header?: ReactNode;
    "data-id"?: string;
    onClick?: () => void;
    border?: {
        color: Color;
        position: "left" | "top";
    };
    isSelected?: boolean;
    isDisabled?: boolean;
    footer?: ReactNode;
};

export default function BaseCardContainer({
    children,
    leftChildren,
    rightChildren,
    header,
    "data-id": dataId,
    onClick,
    border,
    isDisabled,
    isSelected,
    footer
}: BaseCardContainerInterface): JSX.Element {
    return (
        <div
            data-id={dataId}
            {...(!isDisabled && { onClick })}
            className={classNames("flex-1 overflow-hidden rounded-lg", {
                "cursor-pointer": onClick,
                "pointer-events-none": isDisabled,
                "text-white bg-slate-primary": isSelected,
                "text-slate-primary bg-white ": !isSelected
            })}
        >
            <div
                children={
                    <div
                        className={classNames("flex w-full flex-col", {
                            "h-full": footer
                        })}
                    >
                        {header && (
                            <div className="flex w-full">
                                <header className="w-full">{header}</header>
                            </div>
                        )}
                        <div
                            className={classNames("flex w-full", {
                                "flex-1 flex-col": footer
                            })}
                        >
                            {leftChildren}
                            <div className="flex w-full flex-col md:flex-row">
                                {children}
                                {rightChildren && <div className="flex min-w-max items-center gap-5 p-5">{rightChildren}</div>}
                            </div>
                            {footer && <div className="flex flex-1 items-end p-5">{footer}</div>}
                        </div>
                    </div>
                }
                className={classNames("h-full transition-opacity duration-500", {
                    [`border-${border?.color}-primary`]: border?.color,
                    "border-l-8": border?.color && border?.position === "left",
                    "border-t-8": border?.color && border?.position === "top",
                    "opacity-50": isDisabled,
                    flex: !!leftChildren || !!rightChildren,
                    "bg-slate-primary text-white": isSelected,
                    "text-dark": !isSelected
                })}
            />
        </div>
    );
}
