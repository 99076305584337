import { EvidencyApp } from "@vaultinum/evidency-api";
import { Account, Deposit, DepositStore, Escrow, FullAudit, StaffUser, sum } from "@vaultinum/vaultinum-api";
import { getAppDeposit } from "@vaultinum/vaultinum-api/helpers/AccountHelper";
import {
    addQueryParamsToUrl,
    BreadcrumbItems,
    getAccount,
    getAccountAppSettings,
    getFullAuditsByAccountIdAndDomain,
    getGitConnections,
    Spin,
    Tabs,
    useUrlSearch
} from "@vaultinum/vaultinum-sdk";
import fileSize from "filesize";
import { kebabCase } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { filterDepositsWithoutEscrow } from "../../../common/VaultTools";
import { ModelItemPageHeader, TitleWithBadge } from "../../../components";
import { AppName } from "../../../helpers";
import { getEventsCount } from "../../../services/eventService";
import { ACCOUNTS_PAGE, AccountTabPage } from "../../../services/routing.service";
import {
    getDepositsByAccount,
    getDepositStoresForAccount,
    getDepositStoresWithDeposits,
    getEscrowsAsBeneficiaryForAccount,
    getEscrowsAsSupplierForAccount
} from "../../../services/vaultService";
import { EventsList } from "../components";
import AccountCard from "./AccountCard";
import AccountPartnerInvitations from "./AccountPartnerInvitations";
import AccountPartners from "./AccountPartners";
import AccountUsers from "./AccountUsers";
import DepositPlan from "./DepositPlan";
import DepositStores from "./DepositStores";
import Escrows from "./Escrows";
import FullAudits from "./FullAudits";
import GitConnections from "./GitConnections";
import EvidencyPlan from "./EvidencyPlan";

export default function AccountPage({ staffUser }: { staffUser: StaffUser }) {
    const { accountId } = useParams<{ accountId: string }>();
    const [account, setAccount] = useState<Account | null | undefined>();
    const [accountAppSettings, setAccountAppSettings] = useState<Account.AppSettings[]>([]);
    const [deposits, setDeposits] = useState<Deposit[] | null>([]);
    const [eventsCount, setEventsCount] = useState<number>(0);
    const [depositStores, setDepositStores] = useState<DepositStore[] | null>([]);
    const [escrowsAsSupplier, setEscrowsAsSupplier] = useState<Escrow[] | undefined>(undefined);
    const [escrowsAsBeneficiary, setEscrowsAsBeneficiary] = useState<Escrow[] | undefined>(undefined);
    const [fullAudits, setFullAudits] = useState<FullAudit[] | undefined>();

    const [gitConnections, setGitConnections] = useState<Account.GitConnection[] | undefined>();

    const searchUrlParams = useUrlSearch() as { tab?: string };
    const navigate = useNavigate();

    const escrows = useMemo(
        () => [...(escrowsAsSupplier || []), ...(escrowsAsBeneficiary || [])].sort((a, b) => b.creationDate?.getTime() - a.creationDate?.getTime()),
        [escrowsAsSupplier, escrowsAsBeneficiary]
    );

    useEffect(() => getAccount(accountId, setAccount), [accountId]);
    useEffect(() => getAccountAppSettings(accountId, setAccountAppSettings), [accountId]);

    useEffect(() => getEventsCount(setEventsCount, accountId), [accountId]);

    useEffect(() => getDepositStoresForAccount(accountId, setDepositStores), [accountId]);
    useEffect(() => getDepositsByAccount(accountId, setDeposits), [accountId]);

    useEffect(() => getEscrowsAsSupplierForAccount(accountId, setEscrowsAsSupplier), [accountId]);
    useEffect(() => getEscrowsAsBeneficiaryForAccount(accountId, setEscrowsAsBeneficiary), [accountId]);

    useEffect(() => {
        if (accountId) {
            return getGitConnections(accountId, setGitConnections);
        }
        return () => {};
    }, [accountId]);

    useEffect(() => {
        if (account?.id) {
            return getFullAuditsByAccountIdAndDomain(account.id, account.whiteLabelDomainId, true, setFullAudits);
        }
        return () => {};
    }, [account?.id, account?.whiteLabelDomainId]);
    const activeTab = searchUrlParams.tab || kebabCase(AccountTabPage.Tab.account);

    function onTabChange(newTab: string) {
        navigate(addQueryParamsToUrl(location.pathname, { ...searchUrlParams, tab: kebabCase(newTab) }));
    }

    if (account === undefined) {
        return <Spin />;
    }
    if (account === null) {
        return <h1>No account found for id: {accountId}</h1>;
    }

    const filteredDeposits = filterDepositsWithoutEscrow(deposits || [], depositStores || []);
    const filteredDepositSize = fileSize(
        filteredDeposits
            ?.filter(dep => dep.status === Deposit.Status.SEALED)
            .map(deposit => deposit.size || 0)
            .reduce(sum, 0) || 0
    );
    const depositStoresWithDeposits = getDepositStoresWithDeposits(depositStores || [], filteredDeposits || []);
    const depositApp = getAppDeposit(accountAppSettings);
    const breadcrumbItems: BreadcrumbItems = [
        {
            label: "Accounts",
            href: ACCOUNTS_PAGE
        },
        {
            label: account.companyName
        }
    ];

    return (
        <>
            <ModelItemPageHeader breadcrumbItems={breadcrumbItems} />
            <Tabs activeKey={activeTab} onChange={onTabChange} className="h-full flex-1">
                <Tabs.TabPane className="icon space-y-2" tab={AccountTabPage.Tab.account} key={kebabCase(AccountTabPage.Tab.account)}>
                    <AccountCard account={account} staffUser={staffUser} />
                    <AccountUsers account={account} />
                    <AccountPartners account={account} />
                    <AccountPartnerInvitations account={account} />
                    <GitConnections gitConnections={gitConnections} />
                </Tabs.TabPane>
                {process.env.REACT_APP_BRAND_NAME !== AppName.EVIDENCY && (
                    <>
                        <Tabs.TabPane
                            tab={<TitleWithBadge title={AccountTabPage.Tab.dueDiligence} content={fullAudits?.length} />}
                            key={kebabCase(AccountTabPage.Tab.dueDiligence)}
                        >
                            <FullAudits account={account} staffUser={staffUser} fullAudits={fullAudits || []} />
                        </Tabs.TabPane>
                        <Tabs.TabPane
                            className="space-y-4"
                            tab={<TitleWithBadge title={AccountTabPage.Tab.deposits} content={depositStoresWithDeposits.length} />}
                            key={kebabCase(AccountTabPage.Tab.deposits)}
                        >
                            <DepositPlan account={account} staffUser={staffUser} appSettings={depositApp} depositsSize={filteredDepositSize} />
                            <DepositStores depositStores={depositStoresWithDeposits} />
                        </Tabs.TabPane>
                        <Tabs.TabPane
                            tab={<TitleWithBadge title={AccountTabPage.Tab.escrows} content={escrows.length} />}
                            key={kebabCase(AccountTabPage.Tab.escrows)}
                        >
                            <Escrows escrows={escrows} />
                        </Tabs.TabPane>

                        <Tabs.TabPane
                            tab={<TitleWithBadge title={AccountTabPage.Tab.events} content={eventsCount} />}
                            key={kebabCase(AccountTabPage.Tab.events)}
                        >
                            <EventsList accountId={account.id} />
                        </Tabs.TabPane>
                    </>
                )}
                {process.env.REACT_APP_BRAND_NAME === AppName.EVIDENCY &&
                    ([EvidencyApp.Code.TIMESTAMPING, EvidencyApp.Code.SEALING, EvidencyApp.Code.ARCHIVING] as const).map(app => {
                        const appSettings = accountAppSettings.find(setting => setting.code === app);
                        return (
                            <Tabs.TabPane tab={<TitleWithBadge title={AccountTabPage.Tab[app]} />} key={kebabCase(AccountTabPage.Tab[app])}>
                                <div className="flex flex-col gap-2">
                                    <EvidencyPlan key={app} account={account} staffUser={staffUser} appCode={app} appSettings={appSettings} />
                                </div>
                            </Tabs.TabPane>
                        );
                    })}
            </Tabs>
        </>
    );
}
