import { Spin } from "antd";
import { AxiosResponse, CancelToken } from "axios";
import fileSize from "filesize";
import { sumBy } from "lodash";
import { CSSProperties, useCallback, useEffect, useMemo, useState } from "react";
import { Accept, FileRejection, useDropzone } from "react-dropzone";
import { DragAndDropLogo, openNotificationWithIcon, TrashIcon } from "../../../design-system";
import { useLang } from "../../../lang";
import { NewFile } from "./NewFile";
import { UploadedFileRow } from "./UploadedFileRow";

// Max number of files allowed for upload
const DEFAULT_MAX_TOTAL_FILES = 100;

// One file cannot exceed 2Gb
const DEFAULT_MAX_FILE_SIZE = 2 * 8 ** 10;

// All the files cannot exceed 10Gb
const DEFAULT_MAX_TOTAL_SIZE = 10 * 8 ** 10;

type Props = {
    getFiles: () => Promise<File[]>;
    setIsWorking: (value: boolean) => void;
    buildFormData: (file: File, i: number, chunk: Blob, checksum: string, totalCount: number) => FormData;
    uploadChunkFile: (formData: FormData, cancelToken: CancelToken) => Promise<AxiosResponse>;
    deleteFile?: (filename: string) => Promise<void>;
    fileTypes?: string[];
    accept?: Accept;
    uploadLimitationsText?: string;
    maxTotalFiles?: number;
    maxFileSize?: number;
    maxTotalSize?: number;
    isFileValid?: (file: File) => boolean;
};

export function Uploader({
    setIsWorking,
    getFiles,
    deleteFile,
    buildFormData,
    uploadChunkFile,
    uploadLimitationsText,
    fileTypes,
    accept,
    maxFileSize = DEFAULT_MAX_FILE_SIZE,
    maxTotalSize = DEFAULT_MAX_TOTAL_SIZE,
    maxTotalFiles = DEFAULT_MAX_TOTAL_FILES,
    isFileValid
}: Readonly<Props>): JSX.Element {
    const [files, setFiles] = useState<File[]>([]);
    const [errors, setErrors] = useState<string[]>([]);
    const [working, setWorking] = useState<boolean>(false);
    const [totalSize, setTotalSize] = useState(0);
    const [uploadingQueue, setUploadingQueue] = useState<File[]>([]);
    const lang = useLang();

    const addToQueue = (file: File) => {
        setUploadingQueue(existingQueue => [...existingQueue, file]);
    };

    const removeFromQueue = (filename: string) => {
        setUploadingQueue(existingQueue => existingQueue.filter(fileInQueue => fileInQueue.name !== filename));
    };

    const handleDelete = async (filename: string) => {
        setErrors([]);

        const originalFiles = [...files];
        const originalTotalSize = totalSize;
        const index = files.findIndex(file => file.name === filename);
        setTotalSize(currentTotalSize => currentTotalSize - files[index].size);
        setFiles(currentFiles => currentFiles.filter(file => file.name !== filename));
        removeFromQueue(filename);

        try {
            const uploadedFiles = await getFiles();
            if (uploadedFiles.find(({ name }) => filename === name)) {
                await deleteFile?.(filename);
            }
        } catch {
            setFiles(originalFiles);
            setTotalSize(originalTotalSize);
            openNotificationWithIcon({ type: "error", description: lang.uploadFile.notPossibleToDelete(filename) });
        }
    };

    useEffect(() => {
        setIsWorking(!!uploadingQueue.length);
    }, [uploadingQueue, setIsWorking]);

    function removeFailedFile(failedFile: File) {
        setFiles(files.filter(file => file.name !== failedFile.name));
        removeFromQueue(failedFile.name);
    }

    useEffect(() => {
        void (async () => {
            try {
                setWorking(true);
                const fetchedFiles = await getFiles();
                setTotalSize(sumBy(fetchedFiles, "size"));
                setFiles(fetchedFiles);
            } catch {
                openNotificationWithIcon({ type: "error", description: lang.uploadFile.errorFetchingFiles });
            } finally {
                setWorking(false);
            }
        })();
    }, [setFiles, lang]);

    const onDrop = useCallback(
        (acceptedFiles: File[], fileRejections: FileRejection[]) => {
            setErrors([]);
            fileRejections.forEach(fileRejection => {
                fileRejection.errors.forEach(fileRejectionError => {
                    switch (fileRejectionError.code) {
                        case "file-too-large":
                            openNotificationWithIcon({
                                type: "error",
                                description: lang.uploadFile.maxFileSizeError(fileRejection.file.name, maxFileSize)
                            });
                            break;
                        case "too-many-files":
                            openNotificationWithIcon({
                                type: "error",
                                description: lang.uploadFile.maxNumberOfFilesError(maxTotalFiles)
                            });
                            break;
                        case "file-invalid-type":
                            openNotificationWithIcon({
                                type: "error",
                                description: lang.uploadFile.acceptedFileTypes(fileTypes?.join(", ") ?? "")
                            });
                            break;
                        default:
                            openNotificationWithIcon({
                                type: "error",
                                description: lang.uploadFile.errorWhileUploading
                            });
                            break;
                    }
                });
            });

            if (fileRejections.length) {
                return;
            }

            if (files.length + acceptedFiles.length > maxFileSize) {
                openNotificationWithIcon({
                    type: "error",
                    description: lang.uploadFile.maxNumberOfFilesError(maxFileSize)
                });
                return;
            }

            const acceptedFileNames = acceptedFiles.map(file => file.name);
            const intersectionFiles = files.filter(file => acceptedFileNames.includes(file.name));

            if (intersectionFiles.length) {
                openNotificationWithIcon({
                    type: "error",
                    description: lang.uploadFile.filesAlreadyExistError(intersectionFiles.map(file => file.name).join(", "))
                });
                return;
            }

            const acceptedFilesTotalSize = sumBy(acceptedFiles, "size");

            if (totalSize + acceptedFilesTotalSize > maxTotalSize) {
                openNotificationWithIcon({
                    type: "error",
                    description: lang.uploadFile.maxTotalFileSizeError(fileSize(maxTotalSize))
                });
                return;
            }
            setTotalSize(currentTotalSize => currentTotalSize + acceptedFilesTotalSize);
            acceptedFiles.forEach((newFile: File) => {
                if (!isFileValid?.(newFile)) {
                    return;
                }
                addToQueue(newFile);
                setFiles(currentFiles => [...currentFiles, newFile]);
            });
        },
        [setFiles, files, totalSize, lang]
    );

    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
        onDrop,
        maxSize: maxTotalSize,
        maxFiles: maxTotalFiles,
        accept
    });

    const style = useMemo<CSSProperties>(() => {
        const baseStyle = {
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "40px",
            borderWidth: 2,
            borderRadius: 7,
            borderColor: "#eeeeee",
            backgroundColor: "white",
            color: "#bdbdbd",
            outline: "none",
            transition: "border .24s ease-in-out",
            cursor: "pointer",
            textAlign: "center",
            background: "white"
        } as CSSProperties;

        const activeStyle = {
            borderColor: "#2196f3"
        } as CSSProperties;

        const acceptStyle = {
            borderColor: "#00e676"
        } as CSSProperties;

        const rejectStyle = {
            borderColor: "#ff1744"
        } as CSSProperties;

        return {
            ...baseStyle,
            ...(isDragActive ? activeStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {})
        };
    }, [isDragActive, isDragReject, isDragAccept]);

    return (
        <div className="space-y-6">
            <div className="space-y-2 shadow-md" {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <DragAndDropLogo />
                <p className="text-base font-normal text-dark">{lang.uploadFile.uploadInstructions}</p>
                <p className="text-sm font-light text-dark">
                    {uploadLimitationsText ??
                        lang.uploadFile.uploadLimitations(
                            fileTypes?.join(", ") ?? "",
                            lang.uploadFile.formatSize(maxFileSize),
                            lang.uploadFile.formatSize(maxTotalSize)
                        )}
                </p>
            </div>
            <aside className="mb-12">
                {errors?.map((error, index) => (
                    <p key={index} className="error">
                        {error}
                    </p>
                ))}
                <table className="w-full border-collapse overflow-hidden rounded-lg shadow-md">
                    <thead className="bg-primary text-left text-white">
                        <tr>
                            <th className="p-3">{lang.uploadFile.files}</th>
                            <th className="p-3">{lang.uploadFile.size}</th>
                            <th className="p-3">{lang.uploadFile.progress}</th>
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        {files.length === 0 && (
                            <tr>
                                <td className="p-3">{working ? <Spin /> : lang.uploadFile.noFilesUploadedYet}</td>
                            </tr>
                        )}
                        {files.map((file, index) => (
                            <tr className="border-b" key={file.name}>
                                {uploadingQueue.includes(file) && (
                                    <NewFile
                                        file={file}
                                        setUploadDone={() => removeFromQueue(file.name)}
                                        isReadyToUpload={uploadingQueue[0] === file}
                                        onUploadFailed={removeFailedFile}
                                        buildFormData={buildFormData}
                                        uploadChunkFile={uploadChunkFile}
                                    />
                                )}
                                {!uploadingQueue.includes(file) && <UploadedFileRow file={file} progress={100} />}
                                {!!deleteFile && (
                                    <td onClick={() => handleDelete(file.name)} data-id={`btn-remove-file-${index}`} className="hover:cursor-pointer">
                                        <TrashIcon />
                                    </td>
                                )}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </aside>
        </div>
    );
}
