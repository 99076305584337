/**
 * apiService is used to interact with the backend kys-functions api.
 * It provides the base GET POST PUT DELETE endpoint methods.
 * @module services/api
 */
import { getAuth } from "./firebaseService";
import { addQueryParamsToUrl } from "./routingService";

function parseJson(text: string) {
    try {
        return JSON.parse(text);
    } catch {
        return text;
    }
}

const tokenRevokedReasons = ["auth/user-disabled", "auth/id-token-revoked", "auth/user-not-found"];

async function request<TResponse>(url: string, config: RequestInit = {}): Promise<TResponse> {
    const response = await fetch(url, config);
    const data = parseJson(await response.text());
    if (!response.ok) {
        if (tokenRevokedReasons.includes(data)) {
            await getAuth().signOut();
        }

        return Promise.reject({
            response: {
                ...response,
                data
            }
        });
    }
    return data as TResponse;
}

function isFormData(data: undefined | Record<string, unknown> | FormData): data is FormData {
    return !!data?.append;
}

export async function doRequest<T>(
    path: string,
    method: "GET" | "POST" | "PUT" | "DELETE" | "PATCH",
    data?: Record<string, unknown> | FormData,
    origin?: string,
    optionalConfig?: RequestInit
): Promise<T> {
    const token = await getAuth().currentUser?.getIdToken();
    const config: RequestInit = {
        ...optionalConfig,
        headers: {
            ...optionalConfig?.headers,
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            ...(!isFormData(data) ? { "Content-Type": "application/json" } : {})
        },
        method
    };

    let effectiveUrl = path;
    if (data) {
        if (["POST", "PUT", "DELETE", "PATCH"].includes(method)) {
            config.body = isFormData(data) ? data : JSON.stringify(data);
        } else if (method === "GET") {
            effectiveUrl = addQueryParamsToUrl(path, data as Record<string, unknown>);
        }
    }

    if (origin) {
        return request(`${origin}/${effectiveUrl}`, config);
    }
    return request(`/api/${effectiveUrl}`, config);
}

export function doPost<T>(path: string, data?: Record<string, unknown> | FormData, origin?: string, config?: RequestInit): Promise<T> {
    return doRequest(path, "POST", data, origin, config);
}

export function doPostWithConfigAndData<T>(path: string, config?: RequestInit, data?: Record<string, unknown>): Promise<T> {
    return doRequest(path, "POST", data, undefined, config);
}

export function doPut<T>(path: string, data?: Record<string, unknown>, origin?: string, config?: RequestInit): Promise<T> {
    return doRequest(path, "PUT", data, origin, config);
}

export function doPatch<T>(path: string, data?: Record<string, unknown>, origin?: string, config?: RequestInit): Promise<T> {
    return doRequest(path, "PATCH", data, origin, config);
}

export function doGet<T>(path: string, data?: Record<string, unknown>, origin?: string, config?: RequestInit): Promise<T> {
    return doRequest(path, "GET", data, origin, config);
}

export function doDelete<T>(url: string, data?: Record<string, unknown>, origin?: string): Promise<T> {
    return doRequest(url, "DELETE", data, origin);
}

export async function doGetBinary(url: string, apiHost?: string, mimetype = "application/pdf"): Promise<BlobPart> {
    const token = await getAuth().currentUser?.getIdToken();
    if (!token) {
        throw new Error("Token is missing. The request for retrieving the binary cannot be sent");
    }
    const init: RequestInit = {
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: mimetype
        },
        method: "GET"
    };

    return fetch(`${apiHost || "/api"}/${url}`, init)
        .then(async response => {
            if (response.ok) {
                return response.arrayBuffer();
            }
            const data = parseJson(await response.text());
            if (tokenRevokedReasons.includes(data)) {
                await getAuth().signOut();
            }
            throw new Error("An error occured on download");
        })
        .then(data => data as BlobPart);
}

export function downloadFile(fileName: string, href: string): void {
    const link = document.createElement("a");
    link.href = href;
    link.download = fileName;
    link.click();
    link.remove();
}

export function downloadCanvasImage(canvasElementSelector: string, fileName: string) {
    const canvas = document.querySelector(canvasElementSelector) as HTMLCanvasElement;
    downloadFile(fileName, canvas?.toDataURL("image/png") ?? "");
}

export async function downloadBinary(url: string, options: { fileName: string; apiHost?: string; mimetype: string }): Promise<void> {
    const pdfContent = await doGetBinary(url, options.apiHost, options.mimetype);
    if (pdfContent) {
        const blob = new Blob([pdfContent], { type: options.mimetype });
        downloadFile(options.fileName, window.URL.createObjectURL(blob));
    } else {
        throw new Error("An error occurred on download");
    }
}
