import { FiltersSortService, ListStorageKey, SortDirection, TableStorageKey } from "./filters-sort.service";

export class FiltersSortLocalStorageService implements FiltersSortService {
    private filtersKey: string;

    private sortKey: string;

    constructor(storageKey: TableStorageKey | ListStorageKey) {
        this.filtersKey = `filters-${storageKey}`;
        this.sortKey = `sort-${storageKey}`;
    }

    public saveFilter(key: string, filters?: unknown[]): void {
        const savedFilters = localStorage.getItem(this.filtersKey);
        const parsedFilters = savedFilters ? JSON.parse(savedFilters) : {};
        localStorage.setItem(this.filtersKey, JSON.stringify({ ...parsedFilters, [key]: filters }));
    }

    public loadFilter(key: string): unknown[] {
        return this.loadFilters()[key] || [];
    }

    public loadFilters(): Record<string, unknown[]> {
        const data = localStorage.getItem(this.filtersKey);
        if (!data) {
            return {};
        }
        return JSON.parse(data) || {};
    }

    public saveSort(key: string, direction: string | boolean): void {
        localStorage.setItem(this.sortKey, JSON.stringify({ [key]: direction }));
    }

    public loadSort(key: string): string | boolean {
        return this.loadSorts()[key] || false;
    }

    public loadSorts(): Record<string, SortDirection> {
        const data = localStorage.getItem(this.sortKey);
        if (!data) {
            return {};
        }
        return JSON.parse(data);
    }
}
