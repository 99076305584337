import { FileInfo } from "@vaultinum/vaultinum-api";
import classNames from "classnames";
import { getClassWithColor } from "file-icons-js";
import filesize from "filesize";
import { Dispatch, SetStateAction } from "react";
import {
    ArrowRight,
    Button,
    Column,
    CommonLang,
    CopyIcon,
    FileIcon,
    FolderIcon,
    GitIcon,
    SelectionMode,
    Spin,
    Tables,
    Tooltip,
    copyToClipboard,
    useLang
} from "../..";
import { CodeRepositoryInfo, FileTreeRecord, getFolderColor } from "../../helpers/file-tree.helper";

export type Structure<T extends FileInfo> = {
    items: T[];
    codeRepository?: CodeRepositoryInfo;
};

export type CodeRepositoryFileInfo<T extends FileInfo> = {
    codeRepository: CodeRepositoryInfo;
    fileInfo: T;
};

export type FileTreeProps<T extends FileInfo> = {
    structure: FileTreeRecord<T>[];
    extraColumns?: Column<FileTreeRecord<T>>[];
    selectedRows?: string[];
    setSelectedRows?: Dispatch<SetStateAction<string[]>>;
    onExpand?: (record: FileTreeRecord<T>) => Promise<FileInfo[]>;
    onFileNameClicked?: (record: FileTreeRecord<T>) => void;
    searchText?: string;
    onFilter?: (count: number) => void;
    selectionMode?: SelectionMode;
    buttons?: JSX.Element;
    itemKey?: keyof T;
    defaultSortKey?: [colKey: keyof T, direction: "asc" | "desc"] | null;
    onLoadChildren?: (record: FileTreeRecord<T>) => Promise<FileTreeRecord<T>[]>;
    isTooltipEnabled?: boolean;
};

function orderByFolderFirst<T extends FileInfo>(a: FileTreeRecord<T>, b: FileTreeRecord<T>) {
    return Number(b.isFolder) - Number(a.isFolder);
}

function renderIcon<T extends FileInfo>(record: FileTreeRecord<T>) {
    const icon = record.name ? getClassWithColor(record.name) : null;
    if (record.isFolder) {
        const IconRecord = record.isAtSelectedDepth ? GitIcon : FolderIcon;
        return (
            <span>
                <IconRecord color={getFolderColor(record)} />
            </span>
        );
    }
    return icon ? <span className={icon} /> : <FileIcon />;
}

function renderName<T extends FileInfo>(
    record: FileTreeRecord<T>,
    lang: CommonLang,
    onClick?: (record: FileTreeRecord<T>) => void,
    options?: { isTooltipEnabled?: boolean }
) {
    function copyPath(e?: React.MouseEvent<HTMLDivElement | HTMLButtonElement, MouseEvent>): Promise<void> {
        e?.preventDefault();
        e?.stopPropagation();
        return copyToClipboard(record.path);
    }

    const title = (
        <div className="flex gap-2">
            <div>{record.path}</div>
            <Button type="default" size="sm" children={lang.shared.copy} onClick={copyPath} icon={CopyIcon} isLoading={false} />
        </div>
    );

    const content = (
        <div className="inline-flex w-full items-center gap-2 truncate text-left" {...(onClick && { onClick: () => onClick(record) })}>
            {renderIcon(record)}
            <span
                className={classNames("w-full truncate", {
                    "line-through": record.renamed && record.isAtSelectedDepth,
                    "underline cursor-pointer": onClick && !record.isFolder,
                    "hover:underline cursor-pointer": onClick && record.isFolder
                })}
            >
                {record.name}
            </span>
            {record.renamed && record.isAtSelectedDepth && (
                <span className="gap-2 italic text-gray-400">
                    <ArrowRight color="grey" shade="light" />
                    <span>{record.renamed}</span>
                </span>
            )}
        </div>
    );

    return options?.isTooltipEnabled ? (
        <Tooltip title={title} overlayClassName="tree-filename" delayDuration="long" asChild>
            {content}
        </Tooltip>
    ) : (
        content
    );
}

const renderSize = (size: number) => {
    if (size) {
        return filesize(size, { base: 10 });
    }
    return null;
};

export default function FileTree<T extends FileInfo>({
    structure,
    extraColumns = [] as Column<FileTreeRecord<T>>[],
    selectedRows,
    setSelectedRows,
    onExpand,
    onFileNameClicked,
    searchText,
    onFilter,
    selectionMode,
    itemKey = "path",
    defaultSortKey = ["name", "asc"],
    onLoadChildren,
    isTooltipEnabled = true
}: FileTreeProps<T>): JSX.Element {
    const lang = useLang();
    const sortColumn = defaultSortKey?.length ? defaultSortKey[0] : {};
    const sortDirection = defaultSortKey?.length === 2 ? defaultSortKey[1] : "asc";
    const columns: Column<FileTreeRecord<T>>[] = [
        {
            header: lang.shared.name,
            accessorKey: "name",
            sortingFn: (a, b) => orderByFolderFirst(a.original, b.original) || (a.original.name ?? "").localeCompare(b.original.name ?? ""),
            ...(sortColumn === "name" && { defaultSort: sortDirection }),
            cell: cell => renderName(cell.row.original, lang, onFileNameClicked, { isTooltipEnabled })
        },
        {
            header: lang.fileTree.size,
            accessorKey: "size",
            sortingFn: (a, b) => orderByFolderFirst(a.original, b.original) || (a.original.size ?? 0) - (b.original.size ?? 0),
            ...(sortColumn === "size" && { defaultSort: sortDirection }),
            cell: cell => renderSize(cell.getValue<number>())
        },
        ...extraColumns
    ];

    if (!structure) {
        return <Spin />;
    }

    return (
        <div className="h-full overflow-y-auto">
            <Tables.Tree<FileTreeRecord<T>>
                data={structure}
                columns={columns}
                onLoadChildren={onExpand ? onLoadChildren : undefined}
                searchText={searchText}
                onFilter={onFilter}
                isVirtualized
                itemKey={itemKey}
                setSelectedRows={setSelectedRows}
                selectedRows={selectedRows}
                selectionMode={selectionMode}
            />
        </div>
    );
}
