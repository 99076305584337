import { CodeAnalysis } from "@vaultinum/vaultinum-api";
import { Plugin, PluginExecStatus } from "@vaultinum/vaultinum-kys-api";
import { Button, CellContext, Column, Drawer, FileIcon, IconTag, List, message, Table, useModal } from "@vaultinum/vaultinum-sdk";
import classNames from "classnames";
import dayjs from "dayjs";
import { useContext, useEffect, useState } from "react";
import { formatAnalysisStatus } from "../../../../common/CodeAnalysisReportTools";
import { DownloadReportButton } from "../../../../components";
import { CodeAuditContext } from "../../../../contexts/CodeAuditContext";
import { updateConfiguration } from "../../../../services/analyserService";
import { getInsightPlugins } from "../../../../services/codeAnalysisReportService";
import { ConfigurationModal } from "./modals";
import ResetStatusButton from "./ResetStatusButton";

function isAnalysisDone(insightPlugins: PluginExecStatus[]): boolean {
    return insightPlugins.every(plugin => plugin.status !== Plugin.Status.WORKING);
}

function formatDurationCell(cell: CellContext<PluginExecStatus, unknown>) {
    const duration = cell.getValue<number>();
    if (duration === undefined) {
        return null;
    }
    if (duration < 1000) {
        return `${duration}ms`;
    }
    const seconds = Math.floor((duration / 1000) % 60);
    const minutes = Math.floor((duration / (1000 * 60)) % 60);
    const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
    let display = "";
    if (hours > 0) {
        display += `${hours}h`;
    }
    if (minutes > 0 || (hours > 0 && seconds > 0)) {
        display += `${minutes}m`;
    }
    if (seconds > 0) {
        display += `${seconds}s`;
    }
    return display;
}

function LogLines({ lines = [], isError }: { lines?: string[]; isError?: boolean }): JSX.Element | null {
    if (!lines.length) {
        return null;
    }
    return (
        <div className="space-y-2">
            <h3
                className={classNames({
                    "text-red-500": isError,
                    "text-orange-500": !isError
                })}
            >
                {isError ? "Errors" : "Warnings"}
            </h3>
            <List list={lines} render={item => <div className="py-2">{item}</div>} />
        </div>
    );
}

function LogsDrawer({
    selectedPlugin,
    setSelectedPlugin
}: {
    selectedPlugin: PluginExecStatus | null;
    setSelectedPlugin: (plugin: PluginExecStatus | null) => void;
}): JSX.Element {
    return (
        <Drawer
            header={{
                title: "logs",
                Icon: FileIcon
            }}
            size="lg"
            isVisible={selectedPlugin !== null}
            onClose={() => setSelectedPlugin(null)}
            children={
                <div className="h-full overflow-y-scroll p-2">
                    <LogLines lines={selectedPlugin?.execDetails?.errors} isError />
                    <LogLines lines={selectedPlugin?.execDetails?.warnings} />
                </div>
            }
        />
    );
}

export default function AnalyserLauncher(): JSX.Element {
    const { report, fullAudit } = useContext(CodeAuditContext);
    const [insightPlugins, setInsightPlugins] = useState<PluginExecStatus[]>([]);
    const [selectedPlugin, setSelectedPlugin] = useState<PluginExecStatus | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const { isOpen: isOpenConfig, doOpen: doOpenConfig, doClose: doCloseConfig } = useModal();

    useEffect(() => {
        if (report) {
            return getInsightPlugins(report.fullAuditId, report.id, setInsightPlugins);
        }
        return () => {};
    }, [report]);

    useEffect(() => {
        if (insightPlugins.length && isAnalysisDone(insightPlugins)) {
            setIsLoading(false);
        }
    }, [insightPlugins, report]);

    function renderPluginName(plugin: PluginExecStatus): JSX.Element | null {
        return (
            <div className="flex items-center gap-4">
                {report && <ResetStatusButton report={report} plugin={plugin} />}
                <span>{plugin.pluginKey}</span>
            </div>
        );
    }

    function renderDetails(plugin: PluginExecStatus): JSX.Element | null {
        const { execDetails } = plugin;
        if (!execDetails) {
            return null;
        }
        const hasErrors = !!execDetails.errors.length;
        const hasWarnings = !!execDetails.warnings.length;

        if (!hasErrors && !hasWarnings) {
            return null;
        }

        return (
            <div className="flex space-x-4">
                {hasErrors && <IconTag.Danger message={execDetails.errors.length.toString()} onClick={() => setSelectedPlugin(plugin)} />}
                {hasWarnings && <IconTag.Warning message={execDetails.warnings.length.toString()} onClick={() => setSelectedPlugin(plugin)} />}
            </div>
        );
    }

    const analysisStatusesColumns: Column<PluginExecStatus>[] = [
        {
            header: "Plugin",
            accessorKey: "pluginKey",
            cell: cell => renderPluginName(cell.row.original),
            size: 250
        },
        {
            header: "Version",
            accessorKey: "version",
            size: 65,
            enableColumnFilter: false
        },
        {
            header: "Status",
            accessorFn: row => row.status,
            cell: cell => formatAnalysisStatus(cell.getValue<CodeAnalysis.AnalysisStatus>()),
            size: 140
        },
        {
            header: "Last exec date",
            accessorFn: row => dayjs(row.startTime).format("YYYY-MM-DD HH:mm:ss"),
            size: 160
        },
        {
            header: "Total exec time",
            accessorFn: row => row.execStats?.totalExecTime,
            cell: formatDurationCell,
            size: 140,
            enableColumnFilter: false
        },
        {
            header: "Last exec time",
            accessorFn: row => row.execStats?.execTime,
            cell: formatDurationCell,
            size: 140,
            enableColumnFilter: false
        },
        {
            header: "Exec count",
            accessorFn: row => row.execStats?.totalExecCount,
            size: 120,
            enableColumnFilter: false
        },
        {
            header: "Exec avg",
            accessorFn: row => (row.execStats ? (row.execStats.totalExecTime ?? 0) / (row.execStats.totalExecCount ?? 0) : undefined),
            cell: formatDurationCell,
            size: 120,
            enableColumnFilter: false
        },
        {
            header: "Insert time",
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            accessorFn: row => (row.execStats?.insightsInsertTime ?? 0) + (row.execStats?.insightLinksInsertTime ?? 0),
            cell: formatDurationCell,
            size: 120,
            enableColumnFilter: false
        },
        {
            header: "Insights",
            accessorKey: "insightsCreated",
            size: 100,
            enableColumnFilter: false
        },
        {
            header: "Links",
            accessorKey: "linksCreated",
            size: 100,
            enableColumnFilter: false
        },
        {
            header: "Details",
            accessorFn: row => !!row.execDetails?.errors.length || !!row.execDetails?.warnings.length,
            cell: cell => renderDetails(cell.row.original),
            enableSorting: false
        }
    ];

    async function onUpdateConfigurationClick(configuration: string | undefined) {
        if (!report || !configuration) {
            return;
        }
        doCloseConfig();
        setIsLoading(true);
        try {
            await updateConfiguration(report, configuration);
            void message.success("Configuration successfully updated");
        } catch (error) {
            void message.error(`Update failed: ${error?.response?.data || error?.message}`);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <div className="flex h-full w-full flex-1 flex-col space-y-4">
            <div className="ml-auto flex gap-3">
                <Button
                    type="default"
                    isLoading={false}
                    isDisabled={isLoading || !isAnalysisDone(insightPlugins)}
                    onClick={doOpenConfig}
                    children="Edit configuration"
                />
                {!!insightPlugins.length && isAnalysisDone(insightPlugins) && fullAudit && report && <DownloadReportButton fullAudit={fullAudit} />}
            </div>
            <ConfigurationModal isModalVisible={isOpenConfig} onConfirm={onUpdateConfigurationClick} closeModal={doCloseConfig} report={report} />
            <LogsDrawer selectedPlugin={selectedPlugin} setSelectedPlugin={setSelectedPlugin} />
            {!insightPlugins.length && <div className="flex h-12 w-full items-center justify-center">Analysis not started yet</div>}
            {!!insightPlugins.length && <Table<PluginExecStatus> data={insightPlugins || []} columns={analysisStatusesColumns} />}
        </div>
    );
}
